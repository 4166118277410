import React from 'react';
import styled from 'styled-components';
import { ContactLink } from '../components/ContactLink';
import SEO from '../components/core/Seo';
import Layout from '../layouts/Layout';
import BlankPage from '../templates/BlankPage';
import InfoPage from '../templates/InfoPage';
import { color, pixels, typography } from '../theme';

const Divider = styled.hr`
  background-color: ${color.grey[50]};
  height: ${pixels.one};
  border: none;
  margin-bottom: 3rem;
`;

const Section = styled.div`
  margin-bottom: 3rem;
  color: ${color.grey[90]};

  :last-child {
    margin-bottom: 0;
  }
  
  h4 {
    font-family: ${typography.family.title};
    font-size: ${typography.size.f4};
    margin-bottom: 0.5em;
    color: ${color.grey[100]};
  }

  ul {
    padding-inline-start: 1rem;
    
    li {
      margin-bottom: 0.5em;
    }
  }

  p {
    margin-bottom: 1em;

    :last-child {
      margin-bottom: 0;
    }
  }

  .listmargin {
    margin-bottom: 0.25em;
  }
`;

const SmallPrint = styled.div`
  margin-bottom: 3rem;
  color: ${color.grey[80]};
  font-size: ${typography.size.f7};

  :last-child {
    margin-bottom: 0;
  }

  h4 {
    font-family: ${typography.family.title};
    font-size: ${typography.size.f6};
    margin-bottom: 0.5em;
    color: ${color.grey[100]};
  }

  ul {
    padding-inline-start: 1rem;

    li {
      margin-bottom: 1em;
    }
  }

  p {
    margin-bottom: 1em;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const SportsMassage = () => {
  return (
    <Layout>
      <SEO title="Hollistic Massage" />
      <InfoPage span="Holistic Massage" title="Relax muscles, improve circulation, relieve tension" titleWidth="10">
        <BlankPage>
          <Section>
            <p>Our <b>Holistic Massage</b> treats each person as a whole, taking into account emotional and spiritual well-being as well as the physical body.</p>
            <p>A prescriptive massage with a unique blend of essential oils, in a candle lit, ambient setting will leave your body and mind in a state of pure relaxation.</p>
            <p>Indulge in the tranquil experience you deserve.</p>
          </Section>
          <Section>
            <p><i>This page is currently being updated, sorry for any inconvenience.</i></p>
          </Section>
        </BlankPage>
        <ContactLink />
      </InfoPage>
    </Layout>
  );
};

export default SportsMassage;
